import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { isEmpty, map } from 'lodash';
import {
  getDepartments,
  getHolidaysMap,
  getMilestonesMap,
  getParentDepartments,
  getPeopleMap,
  getPhasesMapRaw,
  getProjectPhases,
  getProjectsMap,
  getRoles,
  getTeamCapacityHighlights,
  getUser,
} from 'selectors';

import { ensureProjectLoaded } from '@float/common/actions/projects';
import { setPlaceholder } from '@float/common/actions/search';
import Loader from '@float/common/components/elements/Loader';
import { useProjectCodesPreference } from '@float/common/hooks/useProjectCodesPreference';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { getCanCurrentUserSeeCostRates } from '@float/common/lib/acl/getCanCurrentUserSeeCostRates';
import { trackEvent } from '@float/common/lib/analytics';
import { handleFail } from '@float/common/lib/errors';
import { useSearchFiltersAnalyticsTracking } from '@float/common/lib/hooks/useSearchFiltersAnalyticsTracking';
import { isCostRateFeatureActive as getIsCostRateFeatureActive } from '@float/common/lib/rates/rates';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { ensureBudgetsLoaded } from '@float/common/store/budgets/budgets.actions';
import { moment } from '@float/libs/moment';
import { FIN } from '@float/theme';
import { ProjectStatus } from '@float/types/project';
import { Button } from '@float/ui/components/Button';
import { DotSeparator } from '@float/ui/components/DotSeparator';
import AccordionTable from '@float/ui/deprecated/AccordionTable/AccordionTable';
import { PURPLE, TEAL } from '@float/ui/deprecated/Chart';
import { BarChart } from '@float/ui/deprecated/Chart/BarChart/BarChart';
import {
  CHART_TYPES,
  UNIT_OPTIONS,
  UNITS,
} from '@float/ui/deprecated/Chart/constants';
import LineChart from '@float/ui/deprecated/Chart/LineChart/LineChart';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';
import { withConfirm } from '@float/ui/deprecated/Modal/withConfirm';
import { useSnackbar } from '@float/ui/deprecated/Snackbar';
import { Tab } from '@float/ui/deprecated/Tab/Tab';
import { showProjectSidePanel } from '@float/web/sidePanel/actions';

import { AccordionTableList } from '../components/AccordionTableList';
import { NoData, NoResults } from '../components/ErrorPages';
import SectionError from '../components/SectionError';
import { getLoggedTimeBoundary } from '../helpers/getLoggedTimeBoundary';
import { getProjectHasPeopleWithMissingCostRates } from '../helpers/getProjectHasPeopleWithMissingCostRates';
import getSearchFiltersString from '../helpers/getSearchFiltersString';
import { useEnsureReportContextLoaded } from '../helpers/useEnsureReportContextLoaded';
import parseBarChartData from '../ProjectsOverview/parser/chart';
import { ReportGlobalControls } from '../ReportGlobalControls';
import { LoaderContainer, TabContainer } from '../styles';
import { useReportsFetcher } from '../useReportsFetcher';
import { useReportsStateReducer } from '../useReportsStateReducer';
import PhaseBars from './components/PhaseBars';
import SummaryBar from './components/SummaryBar';
import SummaryBarLegacy from './components/SummaryBarLegacy';
import parseLineChartData from './parser/chart';
import exportChartCsv from './parser/csv/chart';
import exportTableCsv from './parser/csv/table';
import exportTimetrackingCsv from './parser/csv/timetracking';
import parseTableData from './parser/table';
import { useEffectUpdateComparisonMode } from './ProjectDetails.hooks';
import {
  getEffectiveDateRange,
  useProjectRangeOpts,
} from './useProjectRangeOpts';
import { useUpdateProjectNameFilter } from './useUpdateProjectNameFilter';

import * as styles from './styles.css';

function getColorByMode(mode) {
  switch (mode) {
    case ChartComparisonModes.LOGGED:
      return PURPLE;
    case ChartComparisonModes.SCHEDULED:
      return TEAL;
    default:
      return undefined;
  }
}

function ProjectDetails(props) {
  const {
    width,
    reportsSettings: { settings, updateSettings, createSortConfig },
    dispatch: reduxDispatch,
    setCsvExportConfig,
    searchFilters,
    confirm,
    printMode,
    setPrintModeHeader,
    projectId,
    wrapperRef,
    timeoffApprovalsEnabled,
  } = props;
  const updateProjectNameFilter = useUpdateProjectNameFilter();
  const { isProjectCodesEnabled } = useProjectCodesPreference();

  const { dates } = useScheduleContext();
  const [state, dispatch] = useReportsStateReducer();

  const [projectLoaded, setProjectLoaded] = useState(null);
  const projectBudgetExplicitlyRequested = useRef(false);

  // We want to make sure that the requested project has been specifically
  // fetched so that we have correct start/end dates. By doing this, we prevent
  // an extra fetch against the reports API.
  const project = projectId == projectLoaded && props.projects[projectId];
  const hasPhases = !!props.projectPhases[projectId]?.length;
  const hasBudgetsAccess = getCanCurrentUserSeeBudgets(props.user);
  const hasCostsAccess = getCanCurrentUserSeeCostRates(props.user);
  const hasPeopleWithMissingCostRates = getProjectHasPeopleWithMissingCostRates(
    project,
    props.projectPhases[projectId],
    props.people,
    props.roles,
  );

  const isCostRateFeatureActive = getIsCostRateFeatureActive();
  const isDraftProjectReport = project?.status === ProjectStatus.Draft;
  const showProjectCode = project?.project_code && isProjectCodesEnabled;

  const [activeTab, setActiveTab] = useState(hasPhases ? 'phases' : 'tasks');
  const [suppressMonthUnit, setSuppressMonthUnit] = useState(false);
  const [barChartScale, setBarChartScale] = useState(null);
  // reports settings aren't being updated from the calendar picker in this
  // component but we need to keep track in order to handle date updates properly.
  const [localRangeMode, setLocalRangeMode] = useState('entireProject');
  const [{ startDate, endDate }, setDates] = useState({
    startDate: settings.startDate,
    endDate: settings.endDate,
  });

  const searchFiltersString = getSearchFiltersString(searchFilters);

  useEffectUpdateComparisonMode(
    props.timeTrackingEnabled,
    isDraftProjectReport,
    settings,
    updateSettings,
  );

  const setProjectMarginType = useCallback(
    (projectMarginType) => {
      updateSettings({ projectMarginType });
    },
    [updateSettings],
  );

  // Fetch lifecycle -----------------------------------------------------------

  const reportParams = useMemo(
    () => ({
      ...settings,
      startDate,
      endDate,
      project,
      timeoffApprovalsEnabled,
    }),
    [endDate, project, settings, startDate, timeoffApprovalsEnabled],
  );

  useReportsFetcher({
    endpoint: `projects/${project?.project_id}`,
    dispatch,
    settings: reportParams,
    loggedTimeUpdateCount: props.loggedTimeUpdateCount,
    enabled: Boolean(project),
  });

  useEnsureReportContextLoaded(startDate, endDate);

  // Fetch the project budget explicitly when it's archived
  // by default the app fetches only the budgets of the projects
  // which are not archived
  useEffect(() => {
    // Don't fetch if the project is not loaded, or is active
    // or was already requested
    // (prevents extra requesting budget on state project change)
    if (
      !project?.project_id ||
      project.active ||
      projectBudgetExplicitlyRequested.current
    )
      return;

    reduxDispatch(
      ensureBudgetsLoaded(project.project_id, {
        includeArchived: true,
        forceLoad: true,
      }),
    );

    // Mark project budget as explicitly requested, to avoid of
    // re-requesting on project update
    projectBudgetExplicitlyRequested.current = true;
  }, [project, projectBudgetExplicitlyRequested, reduxDispatch]);

  const parseAndSetProjectDates = useCallback(
    (project) => {
      const range = getEffectiveDateRange(project.project_dates || project);

      // only update date if the date range is entire project mode and dates have changed
      if (
        localRangeMode === 'entireProject' &&
        (startDate !== range.startDate || endDate !== range.endDate)
      ) {
        setDates({ startDate: range.startDate, endDate: range.endDate });
      }
    },
    [setDates, startDate, endDate, localRangeMode],
  );

  useEffect(() => {
    if (!projectId) return;

    let isActive = true;

    reduxDispatch(ensureProjectLoaded(projectId)).then((project) => {
      if (!isActive) return;

      const range = getEffectiveDateRange(project.project_dates || project);
      setDates({ startDate: range.startDate, endDate: range.endDate });
      setProjectLoaded(projectId);
    });

    return () => {
      isActive = false;
    };
  }, [reduxDispatch, projectId, updateSettings]);

  useEffect(() => {
    const dayDiff = moment(endDate).diff(moment(startDate), 'days');
    const daysInMonth = moment(startDate).daysInMonth();
    setSuppressMonthUnit(dayDiff < daysInMonth);
  }, [startDate, endDate]);

  useEffect(() => {
    if (!state.isTableTimeout && !state.isChartTimeout) return;

    const err = state.isTableTimeout ? state.tableError : state.chartError;

    confirm({
      title: err.message || 'Report taking too long',
      message: 'Please narrow the date range and try again.',
      hideCancel: true,
    });
  }, [state, confirm]);

  // Parse chart data ----------------------------------------------------------

  const { domLoggedTimeBoundary, csvLoggedTimeBoundary } =
    getLoggedTimeBoundary({
      timeTrackingEnabled: props.timeTrackingEnabled,
      comparisonMode: settings.comparisonMode,
    });

  const { chartData, chartDataKey } = useMemo(() => {
    const lineChartData = state.rawChartData
      ? parseLineChartData(
          project,
          state.rawChartData,
          settings.comparisonMode,
          startDate,
          props.timeTrackingEnabled,
          props.budgets,
          hasBudgetsAccess,
          props.currencySymbol,
        )
      : {};

    const domBarChartData = parseBarChartData(
      {
        dates,
        settings: { ...settings, startDate },
        loggedTimeBoundary: domLoggedTimeBoundary,
      },
      state.rawChartData?.datapoints,
      props.highlights,
    );
    const csvBarChartData = parseBarChartData(
      {
        dates,
        settings: { ...settings, startDate },
        loggedTimeBoundary: csvLoggedTimeBoundary,
      },
      state.rawChartData?.datapoints,
      props.highlights,
    );

    return {
      chartData: {
        dom: {
          lineChart: lineChartData,
          barChart: {
            loggedTimeBoundary: domLoggedTimeBoundary,
            loggedTimeBoundaryIdx: domBarChartData.loggedTimeBoundaryIdx,
            chartData: domBarChartData.chartData,
            chartTotals: domBarChartData.chartTotals,
          },
        },
        csv: {
          barChart: {
            loggedTimeBoundary: csvLoggedTimeBoundary,
            loggedTimeBoundaryIdx: csvBarChartData.loggedTimeBoundaryIdx,
            chartData: csvBarChartData.chartData,
            chartTotals: csvBarChartData.chartTotals,
          },
        },
      },
      chartDataKey: String(Date.now()),
    };
  }, [
    dates,
    project,
    state.rawChartData,
    settings,
    startDate,
    props.highlights,
    props.timeTrackingEnabled,
    props.budgets,
    domLoggedTimeBoundary,
    csvLoggedTimeBoundary,
    hasBudgetsAccess,
    props.currencySymbol,
  ]);

  // Parse table data ----------------------------------------------------------

  const tableData = useMemo(() => {
    return parseTableData(
      {
        dates,
        people: props.people,
        project,
        projects: props.projects,
        phases: props.phases,
        hasPhases,
        mode: settings.comparisonMode,
        percentageMode: settings.percentageMode,
        reduxDispatch,
        user: props.user,
        loggedTimeBoundary: domLoggedTimeBoundary,
        hasBudgetsAccess,
        hasCostsAccess,
      },
      state.rawTableData,
    );
  }, [
    dates,
    project,
    props.projects,
    state.rawTableData,
    props.people,
    props.phases,
    hasPhases,
    settings.comparisonMode,
    settings.percentageMode,
    reduxDispatch,
    props.user,
    domLoggedTimeBoundary,
    hasBudgetsAccess,
    hasCostsAccess,
  ]);

  // Parse markers -------------------------------------------------------------

  const dailyHighlights = useMemo(() => {
    return map(props.highlights, (val, date) => {
      if (date < startDate || date > endDate) {
        return null;
      }

      return {
        date,
        highlights: val,
      };
    }).filter(Boolean);
  }, [props.highlights, startDate, endDate]);

  // CSV export and print ------------------------------------------------------

  const { showSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const ctx = {
      settings: {
        ...settings,
        startDate,
        endDate,
      },
      project,
      people: props.people,
      departments: props.departments,
      parentDepartments: props.parentDepartments,
      timeTrackingEnabled: props.timeTrackingEnabled,
      user: props.user,
      searchFiltersString,
      projects: props.projects,
      phases: props.phases,
      budgets: props.budgets,
      loggedTimeBoundary: csvLoggedTimeBoundary,
    };

    const handleExport =
      (fn, ...rest) =>
      async () => {
        const id = showSnackbar('Exporting', { loader: true, persist: true });
        try {
          await fn(ctx, ...rest);
        } catch (e) {
          console.error(e);
          handleFail(
            null,
            'There was an error exporting the requested data. Please refresh the page and try again.',
          );
        } finally {
          closeSnackbar(id);
        }
      };

    const options = [
      {
        title: 'Export chart data',
        fn: handleExport(
          exportChartCsv,
          chartData.csv.barChart.chartData,
          chartData.csv.barChart.chartTotals,
        ),
      },
      {
        title: 'Export table data',
        fn: handleExport(
          exportTableCsv,
          state.rawTableData,
          chartData.csv.barChart.chartTotals,
        ),
      },
    ];

    if (props.timeTrackingEnabled) {
      options.push({
        title: 'Export time tracking data',
        fn: handleExport(exportTimetrackingCsv, state.rawTableData),
      });
    }

    setCsvExportConfig({ options });
  }, [
    project,
    chartData.csv.barChart,
    state.rawTableData,
    settings,
    showSnackbar,
    closeSnackbar,
    setCsvExportConfig,
    props.user,
    props.timeTrackingEnabled,
    props.projects,
    props.people,
    props.departments,
    props.parentDepartments,
    props.phases,
    props.budgets,
    searchFiltersString,
    csvLoggedTimeBoundary,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setPrintModeHeader({
      title: project.project_name,
      searchFiltersString: '',
    });
  }, [project.project_name, setPrintModeHeader]);

  // Time unit normalization ---------------------------------------------------

  useEffect(() => {
    if (settings.timeUnit === UNITS.WEEK) {
      // Week mode isn't supported in project details
      updateSettings({ timeUnit: UNITS.DAY });
    }

    if (suppressMonthUnit && settings.timeUnit === UNITS.MONTH) {
      updateSettings({ timeUnit: UNITS.DAY });
    }
  }, [settings.timeUnit, projectLoaded, suppressMonthUnit]); // eslint-disable-line

  const projectRangeOpts = useProjectRangeOpts(project);

  const datePickerSettings = useMemo(
    () => ({
      ...settings,
      ...projectRangeOpts,
      startDate,
      endDate,
      rangeMode: localRangeMode,
    }),
    [settings, startDate, endDate, projectRangeOpts, localRangeMode],
  );

  useEffect(() => {
    reduxDispatch(setPlaceholder('1 project'));
  }, []); // eslint-disable-line

  useEffect(() => {
    trackEvent('report-viewed', { type: 'Project' });
  }, []);

  useSearchFiltersAnalyticsTracking('report-filtered', {
    additionalProps: {
      type: 'Project',
    },
  });

  // ---------------------------------------------------------------------------

  const unitOptions = useMemo(
    () =>
      suppressMonthUnit
        ? UNIT_OPTIONS.filter((o) => o.value === UNITS.DAY)
        : UNIT_OPTIONS.filter((o) => o.value !== UNITS.WEEK),
    [suppressMonthUnit],
  );

  if (!state.hasAttemptedFetch) return null;
  if (project && (!project.start_date || !project.end_date)) {
    return <NoData isProjectDetails />;
  }

  if (
    !state.isChartLoading &&
    !state.isTableLoading &&
    !state.isChartTimeout &&
    !state.isTableTimeout &&
    !state.chartError &&
    !state.tableError &&
    isEmpty(chartData.dom.lineChart) &&
    isEmpty(chartData.dom.barChart.chartData)
  ) {
    return <NoResults />;
  }

  let PhaseBarsElem = null;
  if (hasPhases) {
    let visibleStart = startDate;
    let visibleEnd = endDate;

    // In line chart logged view, we need to restrict the ranges to the visible
    // dates. This theoretically only impacts restricting the end date to
    // yesterday, but we'll adjust both ranges just to be sure.
    const datapoints = chartData.dom.lineChart.datapoints;
    if (settings.chartType === CHART_TYPES.LINE && datapoints) {
      visibleStart = moment(datapoints[0].key).format('YYYY-MM-DD');
      visibleEnd = moment(datapoints[datapoints.length - 1].key).format(
        'YYYY-MM-DD',
      );
    }

    PhaseBarsElem = (
      <PhaseBars
        width={printMode ? 1000 : width}
        barChartScale={barChartScale}
        type={settings.chartType}
        unit={settings.timeUnit}
        dates={dates}
        startDate={visibleStart}
        endDate={visibleEnd}
        phases={props.projectPhases[projectId]}
        project={props.projects[projectId]}
      />
    );
  }

  const openProjectPanel = () => {
    const initialProject = project;
    reduxDispatch(
      showProjectSidePanel({
        projectId,
        afterSave: (id) => {
          reduxDispatch(ensureProjectLoaded(id)).then((project) => {
            // If the project name has changed, update the project name filter other wise the page breaks
            if (project.project_name !== initialProject?.project_name) {
              updateProjectNameFilter(project.project_name);
            }
            // Date is set only when the initial project loads or when the user changes
            // the setting in the picker so we need to set it again here
            parseAndSetProjectDates(project);

            // Triggers hydrating the panel with new project data
            setProjectLoaded(id);
          });
        },
      }),
    );
  };

  const zoom = printMode ? 0.77 : 1;

  return (
    <>
      <div>
        <Button
          onClick={openProjectPanel}
          appearance="clear-flay"
          size="xsmall"
          className={styles.projectTitleButton}
        >
          {project.project_name}
          {showProjectCode && (
            <>
              <DotSeparator fill={FIN.Color.Text.Subdued} />
              {project.project_code}
            </>
          )}
        </Button>
        {!printMode && (
          <ReportGlobalControls
            hideTeamModeFilters={props.hideTeamModeFilters}
            memberViewSelf={props.memberViewSelf}
            csvExportConfig={props.csvExportConfig}
            reportsSettings={props.reportsSettings}
            timeTrackingEnabled={props.timeTrackingEnabled}
            datePickerSettings={datePickerSettings}
            unitOptions={unitOptions}
            includeChartTypeSelection
            isDraftProjectReport={isDraftProjectReport}
            onDateRangeChange={(sd, ed, rangeMode) => {
              sd = moment(sd);
              ed = moment(ed);

              const dayDiff = moment(ed).diff(moment(sd), 'days');
              const daysInMonth = moment(sd).daysInMonth();
              if (dayDiff < daysInMonth) {
                updateSettings({ timeUnit: UNITS.DAY });
              }
              setLocalRangeMode(rangeMode);
              setDates({
                startDate: sd.format('YYYY-MM-DD'),
                endDate: ed.format('YYYY-MM-DD'),
              });
            }}
          />
        )}

        {state.chartError && <SectionError height="268" topMargin />}
        {!state.chartError &&
          (state.isChartLoading && state.showChartSpinner ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : settings.chartType === CHART_TYPES.LINE ? (
            <>
              <LineChart
                key={`${chartDataKey}:${printMode}:${width}`}
                width={printMode ? 1000 : width}
                unit={settings.timeUnit}
                color={getColorByMode(settings.comparisonMode)}
                data={chartData.dom.lineChart}
                weekStart={props.startWorkWeek}
                forecast={
                  settings.comparisonMode === ChartComparisonModes.COMBINED
                }
                highlights={dailyHighlights}
              />
              {PhaseBarsElem}
            </>
          ) : (
            <>
              <BarChart
                key={`${chartDataKey}:${printMode}:${width}`}
                setScale={(val) => {
                  setBarChartScale(() => val);
                }}
                width={printMode ? 1000 : width}
                unit={settings.timeUnit}
                mode={settings.comparisonMode}
                items={chartData.dom.barChart.chartData}
                loggedTimeBoundary={chartData.dom.barChart.loggedTimeBoundary}
                loggedTimeBoundaryIdx={
                  chartData.dom.barChart.loggedTimeBoundaryIdx
                }
                noTimeoff
                noCapacity
                noUnscheduled
              />
              {PhaseBarsElem}
            </>
          ))}
      </div>

      {isCostRateFeatureActive ? (
        <SummaryBar
          project={props.projects[projectId]}
          projectBudget={props.budgets.projects[projectId]}
          totals={chartData.dom.barChart.chartTotals}
          baseline={state.rawChartData?.baseline}
          budgets={state.rawTableData?.budgets}
          mode={settings.comparisonMode}
          changeMode={(comparisonMode) => updateSettings({ comparisonMode })}
          hasBudgetsAccess={hasBudgetsAccess}
          hasCostsAccess={hasCostsAccess}
          hasPeopleWithMissingCostRates={hasPeopleWithMissingCostRates}
          projectMarginType={settings.projectMarginType}
          onProjectMarginTypeChange={setProjectMarginType}
        />
      ) : (
        <SummaryBarLegacy
          project={props.projects[projectId]}
          projectBudget={props.budgets.projects[projectId]}
          totals={chartData.dom.barChart.chartTotals}
          baseline={state.rawChartData?.baseline}
          budgets={state.rawTableData?.budgets}
          mode={settings.comparisonMode}
          changeMode={(comparisonMode) => updateSettings({ comparisonMode })}
        />
      )}

      {state.tableError && <SectionError height="100" />}
      {!state.tableError &&
        (state.isTableLoading && state.showTableSpinner ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <div style={{ minWidth: printMode ? 1000 : width }}>
            <TabContainer>
              {hasPhases && (
                <Tab
                  noBorder
                  color="charcoalGrey"
                  label="Phases"
                  onClick={() => {
                    setActiveTab('phases');
                  }}
                  active={activeTab === 'phases'}
                  counter={
                    tableData.phaseBillable.rows.length +
                    tableData.phaseNonBillable.rows.length
                  }
                />
              )}
              <Tab
                noBorder
                color="charcoalGrey"
                label="Tasks"
                onClick={() => {
                  setActiveTab('tasks');
                }}
                active={activeTab === 'tasks'}
                counter={
                  tableData.taskBillable.rows.length +
                  tableData.taskNonBillable.rows.length
                }
              />
              <Tab
                noBorder
                color="charcoalGrey"
                label="Team"
                onClick={() => {
                  setActiveTab('team');
                }}
                active={activeTab === 'team'}
                counter={tableData.team.rows.length}
              />
              {props.timeTrackingEnabled && !isDraftProjectReport && (
                <Tab
                  noBorder
                  color="charcoalGrey"
                  label="Time tracking"
                  onClick={() => {
                    setActiveTab('timetracking');
                  }}
                  active={activeTab === 'timetracking'}
                  counter={tableData.timetracking.rows.length}
                />
              )}
            </TabContainer>
            {activeTab === 'phases' && (
              <AccordionTableList
                top={() =>
                  !project.non_billable && (
                    <AccordionTable
                      wrapperRef={wrapperRef}
                      style={{ zoom }}
                      data={tableData.phaseBillable}
                      sortConfig={createSortConfig('pd-phases-billable')}
                      noResultsMessage={
                        !state.isTableLoading &&
                        'No billable tasks scheduled in this date range'
                      }
                    />
                  )
                }
                bottom={(ref) => (
                  <AccordionTable
                    ref={ref}
                    wrapperRef={wrapperRef}
                    style={{ zoom }}
                    data={tableData.phaseNonBillable}
                    sortConfig={createSortConfig('pd-phases-nonbillable')}
                    noResultsMessage={
                      !state.isTableLoading &&
                      'No non-billable tasks scheduled in this date range'
                    }
                  />
                )}
              />
            )}
            {activeTab === 'tasks' && (
              <AccordionTableList
                top={() =>
                  !project.non_billable && (
                    <AccordionTable
                      wrapperRef={wrapperRef}
                      style={{ zoom }}
                      data={tableData.taskBillable}
                      sortConfig={createSortConfig('pd-tasks-billable')}
                      noResultsMessage={
                        !state.isTableLoading &&
                        'No billable tasks scheduled in this date range'
                      }
                    />
                  )
                }
                bottom={(ref) => (
                  <AccordionTable
                    ref={ref}
                    wrapperRef={wrapperRef}
                    style={{ zoom }}
                    data={tableData.taskNonBillable}
                    sortConfig={createSortConfig('pd-tasks-nonbillable')}
                    noResultsMessage={
                      !state.isTableLoading &&
                      'No non-billable tasks scheduled in this date range'
                    }
                  />
                )}
              />
            )}
            {activeTab === 'team' && (
              <AccordionTable
                wrapperRef={wrapperRef}
                style={{ zoom }}
                data={tableData.team}
                sortConfig={createSortConfig('pd-team')}
                noResultsMessage={
                  !state.isTableLoading &&
                  'No people scheduled in this date range'
                }
              />
            )}
            {activeTab === 'timetracking' && !isDraftProjectReport && (
              <AccordionTable
                wrapperRef={wrapperRef}
                style={{ zoom }}
                data={tableData.timetracking}
                sortConfig={createSortConfig('pd-timetracking')}
                disableAccordion
                noResultsMessage={
                  !state.isTableLoading && 'No logged times in this date range'
                }
              />
            )}
          </div>
        ))}
    </>
  );
}

const mapStateToProps = (state) => ({
  people: getPeopleMap(state),
  roles: getRoles(state),
  departments: getDepartments(state),
  parentDepartments: getParentDepartments(state),
  projects: getProjectsMap(state),
  timeTrackingEnabled: state.companyPrefs.time_tracking > 0,
  currencySymbol: state.companyPrefs.currency_symbol,
  timeoffApprovalsEnabled: !!state.companyPrefs.timeoff_approvals,
  user: getUser(state),
  highlights: getTeamCapacityHighlights(state),
  searchFilters: getActiveFilters(state),
  companyId: state.companyPrefs.uuid,
  startWorkWeek: Number(state.companyPrefs.start_work_week),
  holidays: getHolidaysMap(state),
  milestones: getMilestonesMap(state),
  projectPhases: getProjectPhases(state),
  phases: getPhasesMapRaw(state),
  budgets: state.budgets,
});

export default connect(mapStateToProps)(withConfirm(ProjectDetails));
